.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  background-color: #21212263;
  border: 1px solid #80808063;
  border-radius: 20px;
  gap: 20px;
  padding: 20px 30px;
  text-align: center;
}

.feature > .icon {
  height: 50px;
}
.feature > .title {
  font-size: 18px;
  padding: 0 30px;
  font-weight: bold;
}
.feature > .desc {
  font-size: 15px;
}
