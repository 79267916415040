@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 75px;
  gap: 15px;
  font-family: "Raleway", sans-serif;
}
.home > .crown {
  height: 40px;
}
.home > .text1 {
  font-size: 24px;
}
.home > .text2 {
  font-size: 26px;
  font-weight: 100;
  word-spacing: 5px;
  letter-spacing: 4px;
}
.home > .text3 {
  font-size: 20px;
  letter-spacing: 1px;
}
.home > .features {
  display: flex;
  gap: 40px;
  margin-top: 20px;
}
