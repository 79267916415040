.nav {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0;
  color: white;
}
.nav > .logo {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.nav > .logo > img {
  height: 30px;
}
.nav > .logo > .text {
  display: flex;
  font-family: "Zilla Slab", serif;
  font-size: 18px;
  transition: all 0.3s;
}
.nav > .logo:hover {
  letter-spacing: 1px;
}
.nav > .logo > .text > .first {
  font-weight: 700;
  padding-right: 5px;
  border-left: 1px solid white;
  padding-left: 10px;
}
.nav > .logo > .text > .second {
  font-weight: 100;
}

.nav > .links {
  display: flex;
  gap: 10px;
}
.nav > .links > .navLink {
  text-decoration: none;
  color: white;
  font-family: "Zilla Slab", serif;
  padding: 10px 25px;
  border-radius: 20px;
  font-weight: 600;
}
.nav > .links > .passive {
  background-color: #313131;
  transition: all 0.5s ease-in-out;
}
.nav > .links > .passive:hover {
  background-color: #7a7777;
}
.nav > .links > .active {
  background: linear-gradient(rgb(141, 1, 221), #212121);
}
