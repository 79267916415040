@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&family=Zilla+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: url("../Assets/bg.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}
